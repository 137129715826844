import * as React from "react"
import Layout from "../components/layout"
import WelcomePage from "../components/welcome"
import MonksIntroPage from "../components/monksIntro"
import PerksPage from "../components/perks"
import GalleryPage from "../components/gallery"
import TeamPage from "../components/team"
import Seo from "../components/Seo/Seo";
import FAQPage from "../components/faq"
import RoadmapPage from "../components/roadmap"
import HierarchyPage from "../components/hierarchy"
import FeaturedOn from "../components/featuredOn";

const IndexPage = () => {
    return (
        <Layout>
            <Seo
                ogType="website"
                pageType="WebPage"/>
            <WelcomePage/>
            <MonksIntroPage/>
            <PerksPage/>
            <GalleryPage/>
            <HierarchyPage/>
            {/* <RoadmapPage/> */}
            <TeamPage/>
            <FAQPage/>
            <FeaturedOn/>
        </Layout>
    )
}

export default IndexPage
