import React from "react";
import {StaticImage} from "gatsby-plugin-image";

const FeaturedOn = () => (
    <section id="featured-on" className="section">
        <div className="is-flex is-justify-content-center">
            <a href="https://raritysniper.com/nft-drops-calendar" rel="noopener" target="_blank" className="p-5">
                <StaticImage src="../images/rarity-sniper-logo-1.png" height={120} alt="featured-on-rarity-sniper"
                             formats={["webp"]}/>
            </a>
            <a href="https://nftcalendar.io/event/the-monks-syndicate-by-mafiaverse/" rel="noopener" target="_blank" className="p-5">
                <StaticImage src="../images/nftCalendarLogo.svg" height={120} alt="featured-on-nft-calendar"
                             formats={["webp"]}/>
            </a>
        </div>
    </section>
)

export default FeaturedOn;