import * as React from "react"
import "../styles/team.css"
import { StaticImage } from "gatsby-plugin-image"
import {SocialMediaItem} from "./social/socialMedia";
import {graphql, useStaticQuery} from "gatsby";
import {
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';


const TeamPage = () => {
  const {site} = useStaticQuery(query);
  const {
    maverickTwitterHandle,
    heisenbergTwitterHandle,
    napierTwitterHandle,
} = site.siteMetadata;
  return (
    <section id="team" className="section team-container">
        <h1 className="team-title is-size-5-desktop is-size-6-tablet is-size-6-mobile pb-3">TEAM</h1>
        <div className="columns">
        <div className="team-item-container column is-2 is-hidden-touch"></div>
        <div className="column">
          <div className="team-item-container">
            <StaticImage src="../images/maverick.webp" alt="Display-Picture" style={{zIndex: 100, borderRadius: "1.4rem"}}  />
            <div className="team-member-details has-text-centered">
              <div>
                <span className="team-social-icon-container">
                  <SocialMediaItem 
                    url={`https://twitter.com/${maverickTwitterHandle}`}
                    color={"white"}
                    icon={faTwitter}
                    iconSize={"fa-lg"}
                    label="Follow on Twitter"
                  >
                  </SocialMediaItem>
                </span>
              
                <span className="is-size-6-desktop is-size-6-tablet is-size-7-mobile">Maverick</span>
              </div>
              <p>Development Head</p>
            </div>
          </div>
        </div>
        <div className="column">
        <div className="team-item-container">
          <StaticImage src="../images/heisenberg.webp" alt="Display-Picture" style={{zIndex: 100, borderRadius: "1.4rem"}} />
            <div className="team-member-details has-text-centered">
              <div>
                  <span className="team-social-icon-container">
                    <SocialMediaItem 
                      url={`https://twitter.com/${heisenbergTwitterHandle}`}
                      color={"white"}
                      icon={faTwitter}
                      iconSize={"fa-lg"}
                      label="Follow on Twitter"
                    >
                    </SocialMediaItem>
                  </span>
                  <span className="is-size-6-desktop is-size-6-tablet is-size-7-mobile">Heisenberg</span>
              </div>
              <p>Design Head</p>
            </div>
        </div>
        </div>
        <div className="column">
        <div className="team-item-container">
          <StaticImage src="../images/napier.webp" alt="Display-Picture" style={{zIndex: 100, borderRadius: "1.4rem"}} />
            <div className="team-member-details has-text-centered">
              <div>
                <span className="team-social-icon-container">
                  <SocialMediaItem 
                    url={`https://twitter.com/${napierTwitterHandle}`}
                    color={"white"}
                    icon={faTwitter}
                    iconSize={"fa-lg"}
                    label="Follow on Twitter"
                  >
                  </SocialMediaItem>
                </span>
                <span className="is-size-6-desktop is-size-6-tablet is-size-7-mobile">Napier</span>
              </div>
              <p>Marketing Head</p>
            </div>
        </div>
        </div>
        <div className="team-item-container column is-2 is-hidden-touch"></div>
        </div>
        
    </section>
  )
}

export default TeamPage


const query = graphql`
  query TeamSocialMedia {
    site {
      siteMetadata {
        maverickTwitterHandle
        heisenbergTwitterHandle
        napierTwitterHandle
      }
    }
  }
`;