import * as React from "react"
import "../styles/hierarchy.css"
import rarityDesktop from "../images/rarityDesktop.svg";
import rarityMobile from "../images/rairtyMobile.svg";


const HierarchyPage = () => {
    return (
        <section id="hierarchy" className="section hierarchy-container">
            <h1 className="hierarchy-heading is-size-5-desktop is-size-6-tablet is-size-6-mobile mb-6">
                RARITY
            </h1>
            <div className="is-hidden-touch has-text-centered">
                <img src={rarityDesktop.toString()} width="60%" alt="Monks Syndicate Hierarchy(Rarity)"/>
            </div>
            <div className="has-text-centered is-hidden-tablet">
                <img src={rarityMobile.toString()} width="85%" alt="Monks Syndicate Hierarchy(Rarity)"/>
            </div>
            <div className="has-text-centered is-hidden-desktop is-hidden-mobile">
                <img src={rarityMobile.toString()} width="50%" alt="Monks Syndicate Hierarchy(Rarity)"/>
            </div>
        </section>
    )
}

export default HierarchyPage
