import * as React from "react"
import "../styles/perks.css"
import { StaticImage } from "gatsby-plugin-image"


const PerksPage = () => {
  return (
    <section id="perks" className="section parks-container">
        <h1 className="perks-title is-size-5-desktop is-size-6-tablet is-size-6-mobile pb-5">Perks of owning <span className="perks-title-red"><b>The Monks</b></span></h1>
        <div className="columns is-size-7-desktop is-size-9-tablet is-size-9-mobile">
          <div className="column is-offset-1 perks-left-table-container">
            <table className="perks-table">
              <tbody>
                <tr>
                  <td className="perks-icon p-3">
                  <StaticImage src="../images/3d-avtar-icon.svg" alt="Avtar" width={72} height={72} />
                  </td>
                  <td className="p-3">
                    Badass PFPs now and 3D Avatars later.
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/goodfella-member-icon.svg" alt="Goodfella" width={72} height={72} />
                  </td>
                  <td className="p-3">
                    Become a Goodfella aka Syndicate Member.
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/collab-icon.svg" alt="Collab" width={72} height={72} />
                  </td>
                  <td className="p-3">
                    Benefit from future collabs and partnerships. (Supply of Goods is not just limited to Mafiaverse, think Mutation Drugs, Wearable Gear, etc)
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/sale-icon.svg" alt="Sale" width={72} height={72} />
                  </td>
                  <td className="p-3">
                    Free or heavily discounted access to future collections.
                  </td>
                </tr>
              </tbody>

            </table>
          </div>

          <div className="perks-separator-img-container column is-1 is-hidden-mobile pt-4">
            <StaticImage src="../images/perks-separator.svg" alt="Perks" height={430} />
          </div>

          <div className="perks-right-table-container column">
          <table className="perks-table">
              <tbody>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/vip-icon.svg" alt="VIP" width={72} height={72} />
                  </td>
                  <td className="p-3">
                    Exclusive member only Merchandise - both Physical and Virtual.
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/airdrop-icon.svg" alt="Airdrop" width={72} height={72} />
                  </td>
                  <td className="p-3">
                      Automatic entry into future Airdrops, Giveaways and Raffles.
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/vr-icon.svg" alt="VR" width={72} height={72} />
                  </td>
                  <td className="p-3">
                      HQ in the Metaverse to meet and conduct business.
                  </td>
                </tr>
                <tr>
                  <td className="perks-icon p-3">
                    <StaticImage src="../images/award.svg" alt="VR" width={72} height={72} />
                  </td>
                  <td className="p-3">
                      Get access to the Mafiaverse Operations Portal.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="column is-1 is-hidden-mobile" />
        </div>

    </section>
  )
}

export default PerksPage
