import * as React from "react"
import welcomeToMafiaverse from "../images/welcomeToMafiaverse.svg"
import "../styles/welcome.css"


const WelcomePage = () => {
    return (
        <div id="mafiaverse" className="hero is-fullheight-with-navbar mv-welcome-container">
            <div className="container hero-body is-centered mv-welcome">
                <div className="has-text-centered">
                    <img className="mb-6 head-mafiaverse" src={welcomeToMafiaverse.toString()} alt="Welcome to Mafiaverse"/>
                    <div className="subtitle vision is-size-6-desktop is-size-7-tablet is-size-7-mobile">
                        Step right into the Mafiaverse, a coalition of crime syndicates all set to rule the Metaverse.
                        Mafiaverse gives you access to the most badass PFPs, Avatars, Mutagens and Contraband for the Metaverse. We aim
                        to provide ongoing value to the community in the long run by launching multiple unique projects
                        and we are starting with our first NFT collection known as <b>“The Monks Syndicate”</b> on the Ethereum
                        blockchain.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;
