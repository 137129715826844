import React, {useState} from "react"
import "../styles/faq.css"
import { StaticImage } from "gatsby-plugin-image"
import mobileLogo from "../images/faq-logo-mobile.svg"

const FAQPage = () => {
    const faqs = [
        {
            title: "How and where will the Monks be minted?",
            details: "Minting will take place on https://mint.mafiaverse.io only.",
        },
        {
            title: "How much will it cost to mint a Monk?",
            details: "FREE!!.",
        },
        {
            title: "Wen Mint?",
            details: "Minting is live 🔥",
        },
        {
            title: "How many Monks can I mint?",
            details: "20 Monks per transaction.",
        },
        {
            title: "What is the total supply?",
            details: "The total supply is limited to 10,000 Monks only.",
        }
    ]

    const [state, setState] = useState({
        expandedFAQs : [] as Number[]
    });

    const toggleExpandFAQ = (index : Number) => {
        let expandedFaqsNew : Number[] = state.expandedFAQs
        if (!expandedFaqsNew.includes(index)) {
            expandedFaqsNew.push(index)
        } else {
            const ix = expandedFaqsNew.indexOf(index, 0);
            if (ix > -1) {
                expandedFaqsNew.splice(ix, 1);
            }
        }
        setState(prevState => ({
            ...prevState,
            expandedFAQs: expandedFaqsNew
        }));
    }
    return (
        <section id="faq" className="section faq-container">
            <div className="columns">
            <div className="column is-2 is-hidden-mobile">
                <StaticImage src="../images/faq-logo.svg" alt="FAQ" height={420}  />
            </div>
            <div className="column is-2 is-hidden-tablet has-text-centered">
                <img src={mobileLogo.toString()} alt="FAQ" height={420}  />
            </div>
            <div className="column">
                {faqs.map((row, idx) => {
                    return (
                        <div key={idx} className="faq-row columns is-mobile" onClick={() => toggleExpandFAQ(idx)}>
                            <div className="column is-1 faq-plus-symbol is-size-6">{state.expandedFAQs.includes(idx) ? "-" : "+"}</div>
                            <div className="faq-title-container column is-size-6-desktop is-size-7-tablet is-size-7-mobile">
                                {row.title}
                                {state.expandedFAQs.includes(idx) && <div className="faq-description-container is-size-7">
                                    {row.details}
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="column is-2"></div>
            </div>
        </section>
    )
}

export default FAQPage
