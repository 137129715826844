import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import tmsHeading from "../images/tmsHeading.svg";
import "../styles/monksIntro.css"
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {graphql, useStaticQuery} from "gatsby";


function monkSyndicateHeading() {
    return <div>
            <img src={tmsHeading.toString()} alt="The Monks Syndicate"/>
    </div>;
}

const MonksIntroPage = () => {
    const {site} = useStaticQuery(query);
    const {
        mintPageLink,
    } = site.siteMetadata;
    return (
        <section id="monks" className="section monks-intro-container">
            <div className="columns is-desktop">
                <div className="column is-narrow-desktop">
                    <div className="is-hidden-desktop">
                        {monkSyndicateHeading()}
                    </div>
                    <div style={{maxWidth: "50rem"}}>
                        <StaticImage src="../images/monks-collage.png" alt="Collage" formats={["webp"]}/>
                    </div>
                </div>
                <div className="column">
                    <div className="is-hidden-touch">
                        {monkSyndicateHeading()}
                    </div>
                    <div className="monks-description mt-2 is-size-6-desktop is-size-6-tablet is-size-7-mobile">
                        <p>“The Monks Syndicate” is a collection of 10k algorithmically generated Monks living and
                            ruling the Ethereum underworld. They were once Monks, but now they run the biggest crime
                            syndicate in the Metaverse.</p>
                        <br/>
                        <p>Available now as PFPs with 182 unique visual and skills-based traits and attributes and
                            later
                            as 3D avatars.
                        </p>
                        <br/>
                        <p>
                            These collectible Web3 felons also double as your membership to the Syndicate that comes
                            with a host of benefits and utility.
                        </p>
                    </div>
                    <AnchorLink
                        to={mintPageLink}
                    >
                    <div
                        className="box mt-5 monks-eta has-text-centered is-size-6-desktop is-size-7-tablet is-size-7-mobile bold">
                        Mint Now for Free!
                    </div>
                    </AnchorLink>
                </div>
            </div>
        </section>
    )
}

const query = graphql`
  query MintPageLink {
    site {
      siteMetadata {
        mintPageLink
      }
    }
  }
`;

export default MonksIntroPage
