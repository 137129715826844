import * as React from "react"
import "../styles/gallery.css"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const GalleryPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: false,
    speed: 3000,
    slidesToShow: 7.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 5.2,
          slidesToScroll: 1,
          infinite: true,
          cssEase: "linear",
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 4.1,
          slidesToScroll: 1,
          infinite: true,
          adaptiveHeight: true,
          cssEase: "linear",
        }
      }
    ]
  };
  const settingsRTL = {...settings, rtl: true}
  return (
    <section id="gallery" className="section gallery-container">
        <h1 className="gallery-title is-size-5-desktop is-size-6-tablet is-size-6-mobile py-5"><span className="gallery-title-red"><b>GALLERY</b></span></h1>
        <div className="gallery-row">
          <Slider {...settings}>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147790/mafiaverse_website/gallery-images/gallery-photo-1_tol69q.webp" alt="Display-Picture-1" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-2_dyfzrt.webp" alt="Display-Picture-2" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147790/mafiaverse_website/gallery-images/gallery-photo-3_tcgzvz.webp" alt="Display-Picture-3" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-4_zeruta.webp" alt="Display-Picture-4" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-6_mrnrkk.webp" alt="Display-Picture-6" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-8_enc2eo.webp" alt="Display-Picture-8" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-10_po65ra.webp" alt="Display-Picture-10" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-12_ghjeu0.webp" alt="Display-Picture-12" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-7_ilbgqu.webp" alt="Display-Picture-7" width={210} />
            </div>
          </Slider>
        </div>
        <div className="gallery-row">
          <Slider {...settingsRTL} >
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147793/mafiaverse_website/gallery-images/gallery-photo-5_vutjkg.webp" alt="Display-Picture-5" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-6_mrnrkk.webp" alt="Display-Picture-6" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-7_ilbgqu.webp" alt="Display-Picture-7" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-8_enc2eo.webp" alt="Display-Picture-8" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147790/mafiaverse_website/gallery-images/gallery-photo-1_tol69q.webp" alt="Display-Picture-1" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-4_zeruta.webp" alt="Display-Picture-4" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147793/mafiaverse_website/gallery-images/gallery-photo-11_opkw9w.webp" alt="Display-Picture-11" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-9_ig6epd.webp" alt="Display-Picture-9" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-2_dyfzrt.webp" alt="Display-Picture-2" width={210} />
            </div>
          </Slider>
        </div>
        <div className="gallery-row pb-6">
          <Slider {...settings}>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-9_ig6epd.webp" alt="Display-Picture-9" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-10_po65ra.webp" alt="Display-Picture-10" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147793/mafiaverse_website/gallery-images/gallery-photo-11_opkw9w.webp" alt="Display-Picture-11" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-12_ghjeu0.webp" alt="Display-Picture-12" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147790/mafiaverse_website/gallery-images/gallery-photo-3_tcgzvz.webp" alt="Display-Picture-3" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147792/mafiaverse_website/gallery-images/gallery-photo-8_enc2eo.webp" alt="Display-Picture-8" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147791/mafiaverse_website/gallery-images/gallery-photo-4_zeruta.webp" alt="Display-Picture-4" width={210} />
            </div>
            <div className="gallery-image-container">
              <img src="https://res.cloudinary.com/drczkjl4h/image/upload/c_scale,h_210,q_41,w_210/v1651147793/mafiaverse_website/gallery-images/gallery-photo-5_vutjkg.webp" alt="Display-Picture-5" width={210} />
            </div>
          </Slider>
        </div>
    </section>
  )
}

export default GalleryPage
